import Vue from 'vue'
import App from './App.vue'
import router from './router'

import {BootstrapVue} from 'bootstrap-vue'
import Scrollspy from 'vue2-scrollspy'
import VueScrollTo from 'vue-scrollto'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(Scrollspy)
Vue.use(VueScrollTo)

import "@/assets/scss/style.scss";

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
